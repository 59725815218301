import {
  Box,
  CardMedia,
  Grid,
  Paper,
  ThemeProvider,
  Typography,
  createTheme,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ResponsiveAppBar from "../components/navbar";
import RecipeReviewCard from "../components/productCard";
import Footer from "../components/footer";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3c3c3c",
    },
    secondary: {
      main: "#fafafa",
    },
  },
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginTop: "50px", // Ajoute de l'espace au-dessus du texte
  marginBottom: "50px",
  fontSize: "30px",
  position: "relative",
  display: "inline-block",
  padding: "5px 10px", // Ajoute un peu de marge autour du texte
  borderBottom: `2.5px solid ${theme.palette.primary.main}`, // Bordure en bas
  borderLeft: `2.5px solid ${theme.palette.primary.main}`, // Bordure à gauche
  width: "fit-content", // Ajuste la largeur du conteneur à la taille du texte
  marginRight: "10px", // Ajoute un espace à droite du texte pour la bordure gauche
  "&::before": {
    // Crée une ligne séparée en haut
    content: "''",
    position: "absolute",
    width: "50%", // Largeur de la ligne (incluant les bordures)
    height: "2.5px", // Épaisseur de la ligne
    backgroundColor: theme.palette.primary.main, // Couleur de la ligne
    top: "-2px", // Positionne la ligne juste au-dessus de la bordure supérieure
    left: "-2px", // Positionne la ligne juste au-dessus de la bordure supérieure
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const CategoryDetails = ({ user }) => {
  const { id } = useParams(); // Get the category ID from URL params

  const [categorie, setCategorie] = useState("");

  useEffect(() => {
    // Fetch category details using the ID
    fetch(`https://ste-icem-server.onrender.com/api/v1/categorie/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setCategorie(data.data.categorie);
      });
  }, [id]);

  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      if (categorie && categorie.productList) {
        const productsData = await Promise.all(
          categorie.productList.map((productId) =>
            fetch(
              `https://ste-icem-server.onrender.com/api/v1/product/${productId}`
            )
              .then((response) => response.json())
              .then((data) => data.data.product)
          )
        );
        setProducts(productsData);
      }
    };

    fetchProducts();
  }, [categorie]);

  const handleDelete = async () => {
    try {
      await fetch(
        `https://ste-icem-server.onrender.com/api/v1/categorie/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // Redirect to another route after successful deletion
      window.location.href = "/";
    } catch (error) {}
  };
  return (
    <ThemeProvider theme={theme}>
      <ResponsiveAppBar user={user} />

      <Grid container spacing={0}>
        <Grid xs={4} md={9}>
          <Item
            sx={{
              boxShadow: "none",
              padding: "0",
            }}
          >
            <CardMedia
              component="img"
              height="100"
              width="auto"
              image={`https://ste-icem-server.onrender.com/${categorie.imageCover}`}
              alt={categorie ? categorie.name : ""}
              style={{
                objectFit: "cover",
                filter: "blur(2px)",
              }}
            />
          </Item>
        </Grid>
        <Grid xs={6} md={3}>
          <Item
            sx={{
              boxShadow: "none",
              padding: "0",
              textAlign: "left",
              paddingLeft: "43px",
              display: "flex",
              alignItems: "flex-end", // Align the category name at the bottom
              height: "100px", // Set a fixed height for the container
            }}
          >
            <h2>{categorie.name}</h2>
          </Item>
        </Grid>
      </Grid>
      <hr style={{ height: "2px", backgroundColor: "#000" }} />

      <center>
        <StyledTypography>PRODUITS</StyledTypography>
      </center>
      <Box
        sx={{
          flexGrow: 1,
          paddingLeft: { xs: "5%", md: "10%" },
          paddingRight: { xs: "5%", md: "10%" },
        }}
      >
        <Grid container spacing={4}>
          {products.map((product) => (
            <Grid item xs={12} sm={6} md={4} key={product.id}>
              <RecipeReviewCard product={product} user={user} />
            </Grid>
          ))}
        </Grid>
      </Box>

      <Footer user={user} />
    </ThemeProvider>
  );
};

export default CategoryDetails;
