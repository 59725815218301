import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import styled from "@emotion/styled";
import ResponsiveAppBar from "../components/navbar";
import Footer from "../components/footer";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3c3c3c",
    },
    secondary: {
      main: "#fafafa",
    },
  },
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginTop: "50px",
  marginBottom: "50px",
  fontSize: "30px",
  position: "relative",
  display: "inline-block",
  padding: "5px 10px",
  borderBottom: `2.5px solid ${theme.palette.primary.main}`,
  borderLeft: `2.5px solid ${theme.palette.primary.main}`,
  width: "fit-content",
  marginRight: "10px",
  "&::before": {
    content: "''",
    position: "absolute",
    width: "50%",
    height: "2.5px",
    backgroundColor: theme.palette.primary.main,
    top: "-2px",
    left: "-2px",
  },
}));

function Login({ user }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false); // Nouvel état pour suivre l'état de la connexion
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoggingIn(true); // Mettre à jour l'état de connexion lors de la soumission du formulaire

    try {
      const response = await fetch(
        "https://ste-icem-server.onrender.com/api/v1/users/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Failed to log in");
      }

      localStorage.setItem("jwtToken", data.token);
      const userRole = data.data.user.role;

      if (userRole === "admin") {
        navigate("/admin");
        window.location.reload();
      } else {
        navigate("/");
        window.location.reload();
      }
    } catch (error) {
      setError(error.message);
      setIsLoggingIn(false); // Remettre l'état de connexion à false en cas d'erreur
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <ResponsiveAppBar user={user} />
      <center>
        <StyledTypography>SE CONNECTER</StyledTypography>
      </center>
      <Box
        sx={{
          flexGrow: 1,
          paddingLeft: "10%",
          paddingRight: "10%",
        }}
      >
        <Grid container spacing={5}>
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              marginRight: "10%",
            }}
          >
            <Box
              sx={{
                textAlign: "center",
              }}
            >
              {error && (
                <div
                  style={{
                    width: "220px",
                    marginBottom: "40px",
                    background: "red",
                  }}
                >
                  {error}
                </div>
              )}

              <form
                onSubmit={handleSubmit}
                encType="multipart/form-data"
                sx={{ maxWidth: "800px" }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      label="Email"
                      variant="outlined"
                      fullWidth
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      label="Mot de passe"
                      variant="outlined"
                      fullWidth
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={isLoggingIn} // Désactiver le bouton lors de la connexion
                    >
                      {isLoggingIn ? "Connexion en cours..." : "Se connecter"}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box
              sx={{
                borderLeft: "2px solid black",
                paddingLeft: "20px",
              }}
            >
              <Typography variant="h5">Nouveau client ?</Typography>
              <Typography>
                Créez un compte et bénéficiez de nos avantages :
              </Typography>
              <ul>
                <li>Demander gratuitement des devis en ligne.</li>
              </ul>
              <Box
                sx={{
                  paddingTop: "30px",
                }}
              >
                <Button variant="contained" onClick={() => navigate("/signup")}>
                  Créer un compte
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Footer user={user} />
    </ThemeProvider>
  );
}

export default Login;
