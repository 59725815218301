import { ThemeProvider } from "@emotion/react";
import { Box, Button, Typography, createTheme } from "@mui/material";
import axios from "axios";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import ResponsiveAppBar from "../components/navbar";
import Footer from "../components/footer";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3c3c3c",
    },
    secondary: {
      main: "#fafafa",
    },
  },
});

export const Activation = ({ user }) => {
  const { activationcode } = useParams();
  const navigate = useNavigate();

  axios.post(
    `https://ste-icem-server.onrender.com/api/v1/users/confirm_user/${activationcode}`
  );
  return (
    <ThemeProvider theme={theme}>
      <div>
        <ResponsiveAppBar />
      </div>
      <Box sx={{ paddingTop: "30px" }}>
        <Typography variant="h4" align="center">
          Votre Compte est activé avec succées
        </Typography>
      </Box>
      <Box textAlign={"center"}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/login")}
          style={{ marginTop: "20px" }}
        >
          Se connecter
        </Button>
      </Box>
      <Footer />
    </ThemeProvider>
  );
};
