import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";

import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import { Button, CardMedia, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import logo from "../../logo.svg";

export default function AdminAppBar({ user }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    // Call backend logout route
    fetch(`https://ste-icem-server.onrender.com/api/v1/users/logout`, {
      // Use absolute URL
      method: "GET",
      credentials: "include", // Include cookies in the request
    })
      .then((response) => {
        if (response.ok) {
          // If logout successful, navigate to login page
          localStorage.removeItem("jwtToken");
          navigate("/");
          window.location.reload();
        } else {
          throw new Error("Logout failed");
        }
      })
      .catch((error) => {});
  };

  return (
    <Box sx={{ flexGrow: 1, paddingBottom: "20px" }}>
      <Container maxWidth="xl" style={{ backgroundColor: "#ececec" }}>
        <div style={{ textAlign: "", height: "70px", position: "relative" }}>
          <Box
            sx={{ textAlign: "", height: "70px", position: "relative" }}
            onClick={() => {
              navigate("/admin");
            }}
          >
            <CardMedia
              component="img"
              image={logo}
              alt={"ste icem "}
              sx={{
                objectFit: "cover",
                width: "200px",
                height: "80px",
              }}
            />
          </Box>

          <div
            style={{
              position: "absolute",
              bottom: 5,
              right: 810,
              fontSize: "24px",
              padding: "5px",
              color: "rgba(0, 0, 0, 0.5)",
              borderRadius: "5px",
            }}
          >
            Inter Confort d'Equipement Moderne
          </div>
        </div>
      </Container>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 1 }}
            onClick={() => navigate("/admin")}
          >
            <HomeIcon />
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              ACCUEIL
            </Typography>
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            |
          </Typography>
          {user && (
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
                <Typography>{user.data.data.name}</Typography>
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>
                  <Button onClick={handleLogout} color="inherit">
                    Se déconnecter
                  </Button>
                </MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
