import * as React from "react";
import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
import { useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%", // Adjusted width for responsiveness
  maxWidth: "800px", // Maximum width for larger screens
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({ isOpen, onClose, id }) {
  const [orders, setOrders] = React.useState([]);

  const handleOpen = () => {
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    const fetchOrders = async () => {
      try {
        const response = await fetch(
          `https://ste-icem-server.onrender.com/api/v1/order/getorders/${id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
              // Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Assuming you are using JWT token for authentication
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch orders");
        }
        const data = await response.json();
        setOrders(data.orders);
      } catch (error) {}
    };

    if (isOpen) {
      fetchOrders();
    }
  }, [isOpen, id]); // Add isOpen to the dependency array

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, maxHeight: "80vh", overflowY: "auto" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                  <TableCell>Date</TableCell>
                  <TableCell align="left">Montant Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((row, i) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                    <TableCell align="left">
                      {new Date(row.createdAt).toLocaleDateString("fr-FR")}
                    </TableCell>

                    <TableCell align="left">{row.totalAmount}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </div>
  );
}
