import React, { useState, useEffect } from "react";
import { NotFound } from "./notFound";
import { CircularProgress } from "@mui/material";

export const ProtectedRouteUser = ({ user, children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true); // Set loading to true when user prop changes
  }, [user]);

  useEffect(() => {
    // Simulating a delay to ensure that the UI doesn't change abruptly
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 500); // Adjust the delay as needed

    return () => clearTimeout(timeout);
  }, [user]);

  if (loading) {
    // Show a loading indicator while checking the user's role
    return <CircularProgress />;
  }

  if (user && user.data.data.role !== "user") {
    return <NotFound user={user} />;
  }

  // Otherwise, render the children
  return children;
};
