import * as React from "react";
import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%", // Adjusted width for responsiveness
  maxWidth: "800px", // Maximum width for larger screens
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function OrdersModal({ isOpen, onClose, items }) {
  const handleOpen = () => {
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, maxHeight: "80vh", overflowY: "auto" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                  <TableCell>Produit</TableCell>
                  <TableCell align="left">Quantite</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((row, i) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                    <TableCell align="left">
                      {row.product ? row.product.designation : ""}
                    </TableCell>
                    <TableCell align="left">{row.quantite}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </div>
  );
}
