import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import ResponsiveAppBar from "../components/navbar";
import styled from "@emotion/styled";
import Footer from "../components/footer";
import axios from "axios";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3c3c3c",
    },
    secondary: {
      main: "#fafafa",
    },
  },
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginTop: "50px",
  marginBottom: "50px",
  fontSize: "30px",
  position: "relative",
  display: "inline-block",
  padding: "5px 10px",
  borderBottom: `2.5px solid ${theme.palette.primary.main}`,
  borderLeft: `2.5px solid ${theme.palette.primary.main}`,
  width: "fit-content",
  marginRight: "10px",
  "&::before": {
    content: "''",
    position: "absolute",
    width: "50%",
    height: "2.5px",
    backgroundColor: theme.palette.primary.main,
    top: "-2px",
    left: "-2px",
  },
}));

function Signup({ user }) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    numero: "",
    password: "",
    passwordConfirm: "",
  });
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [submitting, setSubmitting] = useState(false); // État de soumission du formulaire
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true); // Marquer le formulaire comme soumis

    try {
      // Envoyer une requête pour créer un nouvel utilisateur
      const response = await axios.post(
        "https://ste-icem-server.onrender.com/api/v1/users/signup",
        formData
      );

      // Afficher le message de succès et vider les champs du formulaire
      setSuccessMessage(
        "Votre compte a été créé avec succès. Veuillez vérifier votre boîte mail pour l'activer."
      );
      setFormData({
        name: "",
        email: "",
        numero: "",
        password: "",
        passwordConfirm: "",
      });
    } catch (error) {
      // Gérer les erreurs d'inscription
      setError(error.response.data.message);
    } finally {
      setSubmitting(false); // Réinitialiser l'état de soumission du formulaire
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <ResponsiveAppBar user={user} />
      <center>
        <StyledTypography>S'INSCRIRE</StyledTypography>
      </center>
      <Box
        sx={{
          flexGrow: 1,
          paddingLeft: "10%",
          paddingRight: "10%",
        }}
      >
        {error && (
          <div
            style={{
              width: "520px",
              marginBottom: "40px",
              background: "red",
            }}
          >
            {error}
          </div>
        )}
        {successMessage && (
          <div
            style={{
              width: "520px",
              marginBottom: "40px",
              background: "green",
            }}
          >
            {successMessage}
          </div>
        )}
        <form
          onSubmit={handleSubmit}
          encType="multipart/form-data"
          sx={{ maxWidth: "800px" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Nom et Prénom"
                variant="outlined"
                fullWidth
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Numéro tel"
                variant="outlined"
                fullWidth
                name="numero"
                value={formData.numero}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Mot de passe"
                variant="outlined"
                fullWidth
                name="password"
                type="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Confirmer le mot de passe"
                variant="outlined"
                fullWidth
                name="passwordConfirm"
                type="password"
                value={formData.passwordConfirm}
                onChange={handleChange}
                required
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              paddingTop: "30px",
              textAlign: "center",
            }}
          >
            <Button variant="contained" type="submit" disabled={submitting}>
              {submitting ? "En cours..." : "S'INSCRIRE"}
            </Button>
          </Box>
        </form>
      </Box>
      <Footer user={user} />
    </ThemeProvider>
  );
}

export default Signup;
