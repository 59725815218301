import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import ResponsiveAppBar from "../components/navbar";
import styled from "@emotion/styled";
import Footer from "../components/footer";
import React, { useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import {
  CardMedia,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import NumberInput from "../components/numberInput";
import RemoveIcon from "@mui/icons-material/Remove";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3c3c3c",
    },
    secondary: {
      main: "#fafafa",
    },
  },
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginTop: "50px", // Ajoute de l'espace au-dessus du texte
  marginBottom: "50px",
  fontSize: "30px",
  position: "relative",
  display: "inline-block",
  padding: "5px 10px", // Ajoute un peu de marge autour du texte
  borderBottom: `2.5px solid ${theme.palette.primary.main}`, // Bordure en bas
  borderLeft: `2.5px solid ${theme.palette.primary.main}`, // Bordure à gauche
  width: "fit-content", // Ajuste la largeur du conteneur à la taille du texte
  marginRight: "10px", // Ajoute un espace à droite du texte pour la bordure gauche
  "&::before": {
    // Crée une ligne séparée en haut
    content: "''",
    position: "absolute",
    width: "50%", // Largeur de la ligne (incluant les bordures)
    height: "2.5px", // Épaisseur de la ligne
    backgroundColor: theme.palette.primary.main, // Couleur de la ligne
    top: "-2px", // Positionne la ligne juste au-dessus de la bordure supérieure
    left: "-2px", // Positionne la ligne juste au-dessus de la bordure supérieure
  },
}));

function Panier({ user }) {
  const [cart, setCart] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const handleNavigateToCategories = () => {
    navigate("/");

    // Delay scrolling until after navigation
    setTimeout(() => {
      const categoriesAnchor = document.getElementById("categories-anchor");
      if (categoriesAnchor) {
        categoriesAnchor.scrollIntoView({ behavior: "smooth" });
      }
    }, 50); // Adjust the delay time as needed
  };

  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    // Fetch booking data from the backend API
    fetch("https://ste-icem-server.onrender.com/api/v1/cart/getCartItems", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        // Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Assuming you are using JWT token for authentication
      },
    })
      .then((response) => response.json())

      .then((data) => {
        setLoading(false);
        setCart(data.cart);
        setCartItems(data.cart.cartItems);
        setTotalAmount(data.cart.totalAmount);
      })

      .catch((error) => {});
  }, []);

  const handleQuantityChange = async (index, quantity) => {
    const updatedItems = [...cartItems];
    updatedItems[index].quantity = quantity;
    setCartItems(updatedItems);

    // Mettre à jour le montant total dans le panier
    const totalAmount = updatedItems.reduce(
      (acc, item) => acc + item.quantity * item.product.price,
      0
    );
    setCart({
      ...cart,
      totalAmount: totalAmount.toFixed(2), // Assurez-vous que le montant total est arrondi à 2 décimales
    });
    setTotalAmount(totalAmount);

    const token = localStorage.getItem("jwtToken");
    try {
      const response = await fetch(
        "https://ste-icem-server.onrender.com/api/v1/cart/updateCartItems",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            cartId: cart._id,
            cartItems: updatedItems,
            totalAmount: totalAmount,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to update cart items");
      }
    } catch (error) {}
  };

  const handleAddOrder = () => {
    const token = localStorage.getItem("jwtToken");
    fetch("https://ste-icem-server.onrender.com/api/v1/order/addOrder", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        user: user, // Remplacer par l'ID de l'utilisateur réel
        items: cartItems.map((item) => ({
          product: item.product._id,
          quantite: item.quantity,
        })),
        totalAmount: totalAmount,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        window.location.reload();
      })
      .catch((error) => {
        // Handle error response from the backend
        // You can display an error message to the user if needed
      });
  };

  const handleRemoveProduct = async (index) => {
    const updatedItems = [...cartItems];
    updatedItems.splice(index, 1); // Supprimer l'élément à l'index spécifié
    setCartItems(updatedItems);

    // Recalculer le montant total
    const totalAmount = updatedItems.reduce(
      (acc, item) => acc + item.quantity * item.product.price,
      0
    );

    // Mettre à jour le montant total dans le panier
    setCart({
      ...cart,
      totalAmount: totalAmount.toFixed(2), // Assurez-vous que le montant total est arrondi à 2 décimales
    });
    setTotalAmount(totalAmount);

    const token = localStorage.getItem("jwtToken");
    try {
      const response = await fetch(
        "https://ste-icem-server.onrender.com/api/v1/cart/updateCartItems",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            cartId: cart._id,
            cartItems: updatedItems,
            totalAmount: totalAmount,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to update cart items");
      }
    } catch (error) {}
  };
  const handleEmptyCart = () => {
    const token = localStorage.getItem("jwtToken");
    fetch(`https://ste-icem-server.onrender.com/api/v1/cart/${cart._id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          window.location.reload(); // Rafraîchir la page après avoir vidé le panier
        } else {
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    const handleUnload = async () => {
      const token = localStorage.getItem("jwtToken");
      // Enregistrer la carte modifiée dans la base de données
      if (cart && cartItems) {
        try {
          const response = await fetch(
            "https://ste-icem-server.onrender.com/api/v1/cart/updateCartItems",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({
                cartId: cart._id,
                cartItems: cartItems,
                totalAmount: totalAmount,
              }),
            }
          );
          if (!response.ok) {
            throw new Error("Failed to update cart items");
          }
        } catch (error) {}
      }
    };

    // Ajouter un gestionnaire pour l'événement beforeunload
    window.addEventListener("beforeunload", handleUnload);

    // Ajouter un gestionnaire pour l'événement unload
    window.addEventListener("unload", handleUnload);

    // Supprimer les gestionnaires d'événements lors du démontage du composant
    return () => {
      window.removeEventListener("beforeunload", handleUnload);
      window.removeEventListener("unload", handleUnload);
    };
  }, [cart, cartItems, totalAmount]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <ThemeProvider theme={theme}>
      <ResponsiveAppBar user={user} />
      <center>
        <StyledTypography>PANIER</StyledTypography>
      </center>

      {user ? (
        <div>
          {!cartItems || cartItems.length === 0 ? (
            <Box
              sx={{
                flexGrow: 1,
                paddingLeft: "10%",
                paddingRight: "10%",
              }}
            >
              <Typography variant="body1">
                Votre panier est vide. Explorez nos catégories pour choisir des
                produits et passer une commande.
              </Typography>
              <Button
                color="inherit"
                sx={{
                  bgcolor: "#063970",
                  "&:hover": {
                    bgcolor: "#042c4e",
                  },
                  marginTop: "20px",
                  color: " white",
                }}
                onClick={() => handleNavigateToCategories()}
              >
                NOS CATEGORIES
              </Button>
            </Box>
          ) : (
            <div>
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <Box
                    sx={{
                      flexGrow: 1,
                      paddingLeft: "10%",
                      paddingRight: "10%",
                    }}
                  >
                    <TableContainer component={Paper}>
                      <Table aria-label="collapsible table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Image</TableCell>
                            <TableCell align="left">Nom du produit</TableCell>
                            <TableCell align="center">Quantité</TableCell>
                            <TableCell align="left">Retirer</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {cartItems.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell align="right">
                                <CardMedia
                                  component="img"
                                  height="140"
                                  image={`https://ste-icem-server.onrender.com/${row.product.imageCover}`}
                                  alt={row.product.name}
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    objectFit: "cover",
                                    border: "1px solid #ccc",
                                    borderRadius: "5px",
                                  }}
                                />
                              </TableCell>
                              <TableCell align="left">
                                {row.product.designation}
                              </TableCell>
                              <TableCell align="center">
                                <NumberInput
                                  min={1}
                                  defaultValue={row.quantity}
                                  onInputChange={(newValue) => {
                                    handleQuantityChange(index, newValue); // Passer directement la nouvelle valeur à votre gestionnaire
                                  }}
                                />
                              </TableCell>
                              <TableCell align="left">
                                <IconButton
                                  onClick={() => handleRemoveProduct(index)}
                                >
                                  <RemoveIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box
                    sx={{
                      flexGrow: 1,
                      paddingLeft: "10%",
                      paddingRight: "10%",
                    }}
                  >
                    <Typography>MONTANT TOTAL : {totalAmount} </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAddOrder}
                      style={{ marginTop: "20px", marginRight: "20px" }}
                    >
                      Commander
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleEmptyCart}
                      style={{ marginTop: "20px" }}
                    >
                      Vider le panier
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </div>
          )}
        </div>
      ) : (
        <Box
          sx={{
            flexGrow: 1,
            paddingLeft: "10%",
            paddingRight: "10%",
          }}
        >
          <Typography variant="body1">
            Veuillez vous connecter pour afficher votre panier.
          </Typography>
          <Button
            color="inherit"
            sx={{
              bgcolor: "#063970",
              "&:hover": {
                bgcolor: "#042c4e",
              },
              marginTop: "20px",
              color: " white",
            }}
            onClick={() => {
              window.location = "/login";
            }}
          >
            SE CONNECTER
          </Button>
        </Box>
      )}

      <Footer user={user} />
    </ThemeProvider>
  );
}

export default Panier;
