import * as React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ResponsiveAppBar from "../components/navbar";
import SwipeableTextMobileStepper from "../components/carrosel";
import CategoriesCard from "../components/categoriesCard";
import BestProductCard from "../components/bestProductCard";
import Footer from "../components/footer";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3c3c3c",
    },
    secondary: {
      main: "#fafafa",
    },
  },
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginTop: "50px",
  marginBottom: "50px",
  fontSize: "30px",
  position: "relative",
  display: "inline-block",
  padding: "5px 10px",
  borderBottom: `2.5px solid ${theme.palette.primary.main}`,
  borderLeft: `2.5px solid ${theme.palette.primary.main}`,
  width: "fit-content",
  marginRight: "10px",
  "&::before": {
    content: "''",
    position: "absolute",
    width: "50%",
    height: "2.5px",
    backgroundColor: theme.palette.primary.main,
    top: "-2px",
    left: "-2px",
  },
}));

function Home({ user }) {
  return (
    <ThemeProvider theme={theme}>
      <div>
        <ResponsiveAppBar user={user} />
      </div>
      <div style={{ overflow: "hidden" }}>
        <SwipeableTextMobileStepper />
      </div>
      <center>
        <StyledTypography id="categories-anchor">
          NOS CATEGORIES
        </StyledTypography>
      </center>
      <CategoriesCard />

      <BestProductCard user={user} />
      <Footer user={user} />
    </ThemeProvider>
  );
}

export default Home;
