import React, { useState, useEffect, useRef } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Button,
  Grid,
  InputBase,
  alpha,
  styled,
  Container,
  CardActionArea,
  CardMedia,
  Tooltip,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import logo from "../logo.svg";
import { useNavigate } from "react-router-dom";
import Badge from "@mui/material/Badge";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: 100,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

function ResponsiveAppBar({ user }) {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [allProducts, setAllProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [cartItemCount, setCartItemCount] = useState(0); // State to hold the number of items in the cart

  // Your other code

  // Fetch cart items and update the cart item count
  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    fetch("https://ste-icem-server.onrender.com/api/v1/cart/getCartItems", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Count the number of items in the cart
        setCartItemCount(data.cart.cartItems.length);
      })
      .catch((error) => {
        console.error("Error fetching cart items: ", error);
      });
  }, [cartItemCount]);

  const listRef = useRef();

  // Ajouter un gestionnaire d'événements à l'élément parent pour détecter les clics à l'extérieur de la liste filtrée
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (listRef.current && !listRef.current.contains(event.target)) {
        setFilteredProducts([]); // Fermer la liste filtrée si le clic est à l'extérieur
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Fetch product data from the backend API
    fetch("https://ste-icem-server.onrender.com/api/v1/product")
      .then((response) => response.json())
      .then((data) => {
        setAllProducts(data.data.data); // Set the products state with the fetched data
        setFilteredProducts(data.data.data); // Set the filtered products initially
      })
      .catch((error) => {});
  }, []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    // Call backend logout route
    fetch(`https://ste-icem-server.onrender.com/api/v1/users/logout`, {
      // Use absolute URL
      method: "GET",
      credentials: "include", // Include cookies in the request
    })
      .then((response) => {
        if (response.ok) {
          localStorage.removeItem("jwtToken");
          window.location.reload();
        } else {
          throw new Error("Logout failed");
        }
      })
      .catch((error) => {
        // Handle error (e.g., display error message)
      });
  };

  const handleNavigateToCategories = () => {
    navigate("/");

    // Delay scrolling until after navigation
    setTimeout(() => {
      const categoriesAnchor = document.getElementById("categories-anchor");
      if (categoriesAnchor) {
        categoriesAnchor.scrollIntoView({ behavior: "smooth" });
      }
    }, 50); // Adjust the delay time as needed
  };

  const handleFilter = (searchQuery) => {
    setSearchValue(searchQuery); // Mettre à jour la valeur de recherche

    // Si la valeur de recherche est vide, afficher tous les produits
    if (!searchQuery) {
      setFilteredProducts(allProducts);
      return;
    }

    // Filtrer les produits en fonction de la valeur de recherche
    const filtered = allProducts.filter(
      (product) =>
        product.designation &&
        product.designation.toLowerCase().includes(searchQuery.toLowerCase())
    );

    setFilteredProducts(filtered);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl" style={{ backgroundColor: "#ececec" }}>
        <div style={{ textAlign: "", height: "70px", position: "relative" }}>
          <CardActionArea
            sx={{ textAlign: "", height: "70px", position: "relative" }}
            onClick={() => {
              navigate("/");
            }}
          >
            <CardMedia
              component="img"
              image={logo}
              alt={"ste icem "}
              sx={{
                objectFit: "cover",
                width: "200px",
                height: "80px",
              }}
            />
          </CardActionArea>

          <div
            style={{
              position: "absolute",
              bottom: 5,
              right: 810,
              fontSize: "24px",
              padding: "5px",
              color: "rgba(0, 0, 0, 0.5)",
              borderRadius: "5px",
            }}
          >
            Inter Confort d'Equipement Moderne
          </div>
        </div>
      </Container>

      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1 }}>
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              sx={{ display: { xs: "block", sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              <MenuItem
                accuiel
                onClick={() => {
                  navigate("/");
                  handleCloseNavMenu();
                }}
              >
                <Typography>ACCUEIL</Typography>
              </MenuItem>
              <MenuItem
                nosCategories
                onClick={() => {
                  handleNavigateToCategories();
                  handleCloseNavMenu();
                }}
              >
                <Typography>NOS CATEGORIES</Typography>
              </MenuItem>
              <MenuItem
                nous
                onClick={() => {
                  navigate("/nous");
                  handleCloseNavMenu();
                }}
              >
                <Typography>QUI SOMMES NOUS?</Typography>
              </MenuItem>
              {/* <MenuItem
                contact
                onClick={() => {
                  navigate("contact");
                  handleCloseNavMenu();
                }}
              >
                <Typography>CONTACT</Typography>
              </MenuItem> */}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 0, display: { xs: "none", sm: "block" } }}>
            <Button
              acceuil="true"
              color="inherit"
              onClick={() => navigate("/")}
            >
              ACCUEIL
            </Button>
            <Button
              acceuil
              color="inherit"
              onClick={() => handleNavigateToCategories()}
            >
              NOS CATEGORIES
            </Button>
            <Button acceuil color="inherit" onClick={() => navigate("/nous")}>
              QUI SOMMES NOUS?
            </Button>
            {/* <Button acceuil color="inherit" onClick={() => navigate("/")}>
              CONTACT
            </Button> */}
          </Box>

          <Box sx={{ flexGrow: 1, marginLeft: "10%" }}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Chercher..."
                inputProps={{ "aria-label": "search" }}
                onChange={(e) => handleFilter(e.target.value)}
              />
              <div ref={listRef}>
                {searchValue && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      width: "145%",
                      zIndex: 1000,
                      boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
                      backgroundColor: "rgba(128, 128, 128, 1)",
                      maxHeight: "260px",
                      overflowY: "auto",
                      borderRadius: "10px",
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                    }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {filteredProducts.map((product, index) => (
                      <Box
                        sx={{ margin: "20px", cursor: "pointer" }}
                        key={product.id}
                        onClick={(e) => {
                          e.preventDefault();
                          setSearchValue("");
                          navigate(`/viewProduct/${product._id}`);
                        }}
                      >
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            height="140"
                            image={`https://ste-icem-server.onrender.com/${product.imageCover}`}
                            alt={product ? product.designation : ""}
                            style={{
                              width: "50px",
                              height: "50px",
                              objectFit: "contain",
                              border: "1px solid #ccc",
                              borderRadius: "5px",
                            }}
                          />
                          <Typography style={{ color: "black" }}>
                            {product.designation}
                          </Typography>
                        </CardActionArea>
                        {index !== filteredProducts.length - 1 && <hr />}
                      </Box>
                    ))}
                  </Box>
                )}
              </div>
            </Search>
          </Box>

          <Box sx={{ flexGrow: 1, marginLeft: "5%" }}>
            <Grid
              container
              alignItems="center"
              sx={{ flexGrow: 1, marginLeft: "5%" }}
            >
              <Grid xs={6} md={2}>
                <Tooltip title="Panier">
                  <IconButton
                    color="inherit"
                    onClick={() => navigate("/panier")}
                  >
                    {/* Badge component to display the cart item count */}
                    <Badge badgeContent={cartItemCount} color="error">
                      <ShoppingCartOutlinedIcon />
                    </Badge>
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid xs={6} md={2}>
                <Tooltip title="Mon compte">
                  <IconButton
                    color="inherit"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenUserMenu}
                  >
                    <PersonOutlineOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid xs={6} md={2}>
                {user && user.data && user.data.data.name ? (
                  <Typography>{user.data.data.name}</Typography>
                ) : null}
              </Grid>
            </Grid>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {user ? (
                <Button onClick={handleLogout} color="inherit">
                  Se déconnecter
                </Button>
              ) : (
                <Button onClick={() => navigate("/login")} color="inherit">
                  Se connecter
                </Button>
              )}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;
