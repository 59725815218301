import { ThemeProvider } from "@emotion/react";
import { Box, Typography, createTheme } from "@mui/material";
import React from "react";
import ResponsiveAppBar from "../components/navbar";
import NofFound from "../notFound.png";
import Footer from "../components/footer";
import AdminAppBar from "./admin/adminNavbar";
import AdminFooter from "./admin/adminFooter";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3c3c3c",
    },
    secondary: {
      main: "#fafafa",
    },
  },
});

export const NotFound = ({ user }) => {
  return (
    <ThemeProvider theme={theme}>
      {!user || (user && user.data.data.role !== "admin") ? (
        <ResponsiveAppBar />
      ) : (
        <AdminAppBar />
      )}

      <Box
        sx={{
          paddingTop: "30px",
          textAlign: "center",
        }}
      >
        <img src={NofFound} alt={"not found"} loading="lazy" />
        <Typography color={"#646464"} variant="h4">
          Page non trouvée!
        </Typography>
      </Box>
      {!user || (user && user.data.data.role !== "admin") ? (
        <Footer />
      ) : (
        <AdminFooter />
      )}
    </ThemeProvider>
  );
};
