import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import styled from "@emotion/styled";

import { useNavigate } from "react-router-dom";
import AdminAppBar from "./adminNavbar";
import CategoriesCard from "./adminCaegoriesCard";
import AdminFooter from "./adminFooter";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3c3c3c",
    },
    secondary: {
      main: "#fafafa",
    },
  },
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginTop: "50px",
  marginBottom: "20px",
  fontSize: "30px",
  position: "relative",
  display: "inline-block",
  padding: "5px 10px",
  borderBottom: `2.5px solid ${theme.palette.primary.main}`,
  borderLeft: `2.5px solid ${theme.palette.primary.main}`,
  width: "fit-content",
  marginRight: "10px",
  "&::before": {
    content: "''",
    position: "absolute",
    width: "50%",
    height: "2.5px",
    backgroundColor: theme.palette.primary.main,
    top: "-2px",
    left: "-2px",
  },
}));

function ViewCategoriesAdmin({ user }) {
  const navigate = useNavigate();
  return (
    <ThemeProvider theme={theme}>
      <AdminAppBar user={user} />
      <center>
        <StyledTypography>CATEGORIES</StyledTypography>
      </center>
      <Box style={{ padding: "2%", float: "right", marginBottom: "20px" }}>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          onClick={() => {
            navigate("/admin/createCategorie");
          }}
          style={{ marginLeft: "10px" }}
        >
          Ajouter nouelle Categorie
        </Button>
      </Box>
      <Box style={{ clear: "both" }}>
        <CategoriesCard />
      </Box>
      <AdminFooter />
    </ThemeProvider>
  );
}

export default ViewCategoriesAdmin;
