import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import Typography from "@mui/material/Typography";

const Overlay = styled("div")({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  textAlign: "center",
  color: "#fff",
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  position: "relative",
  "&::after": {
    content: "''",
    position: "absolute",
    width: "40%",
    height: "2px",
    backgroundColor: "#eee",
    bottom: "-4px",
    left: 0,
  },
}));

function CategoriesCard() {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetch("https://ste-icem-server.onrender.com/api/v1/categorie")
      .then((response) => response.json())
      .then((data) => {
        setCategories(data.data.data);
      })
      .catch((error) => {});
  }, []);

  return (
    <div>
      <Box sx={{ flexGrow: 1, paddingLeft: "10%", paddingRight: "10%" }}>
        <Grid
          container
          spacing={{ xs: 2, md: 10 }}
          columns={{ xs: 4, sm: 8, md: 16 }}
        >
          {categories.map((categorie, i) => (
            <Grid item xs={2} sm={4} md={4} key={i}>
              <Link to={`/categories/${categorie._id}`}>
                <Card
                  sx={{
                    height: { xs: "100px", md: "220px" },
                    position: "relative",
                  }}
                >
                  <CardActionArea sx={{ height: "100%" }}>
                    <CardMedia
                      component="img"
                      image={`https://ste-icem-server.onrender.com/${categorie.imageCover}`}
                      alt={categorie.name}
                      sx={{
                        objectFit: "cover",
                        height: "100%",
                        width: "100%",
                        filter: "brightness(50%)",
                      }}
                    />
                    <Overlay>
                      <StyledTypography
                        variant="h5"
                        color={"white"}
                        sx={{ fontSize: "clamp(12px, 4vw, 24px)" }}
                      >
                        {categorie.name}
                      </StyledTypography>
                    </Overlay>
                  </CardActionArea>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
}

export default CategoriesCard;
