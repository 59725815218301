import * as React from "react";
import { styled } from "@mui/system";

const NumberInput = styled("input")(({ theme }) => ({
  fontSize: "0.75rem",
  fontFamily: "inherit",
  fontWeight: 400,
  lineHeight: "1.375",
  color: theme.palette.mode === "dark" ? grey[300] : grey[900],
  background: theme.palette.mode === "dark" ? grey[900] : "#fff",
  border: `1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]}`,
  boxShadow: `0px 2px 4px ${
    theme.palette.mode === "dark" ? "rgba(0,0,0, 0.5)" : "rgba(0,0,0, 0.05)"
  }`,
  borderRadius: "8px",
  padding: "4px 8px",
  outline: 0,
  minWidth: 0,
  width: "2rem",
  textAlign: "center",
  marginRight: "10px",
  marginLeft: "10px",
  "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "&:hover": {
    borderColor: grey[400],
  },
  "&:focus": {
    borderColor: grey[400],
    boxShadow: `0 0 0 3px ${
      theme.palette.mode === "dark" ? grey[700] : grey[200]
    }`,
  },
  "&:focus-visible": {
    outline: 0,
  },
}));

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

export default function NumberInputOnly({ min, defaultValue, onInputChange }) {
  const handleChange = (event) => {
    const newValue = event.target.value
      ? parseInt(event.target.value)
      : defaultValue;
    onInputChange(newValue);
  };

  return (
    <NumberInput
      type="number"
      min={min}
      defaultValue={defaultValue}
      onChange={handleChange}
    />
  );
}
