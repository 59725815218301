import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  ThemeProvider,
  Typography,
  createTheme,
  styled,
  Box,
  TextField,
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";

import { useParams } from "react-router-dom";
import AdminAppBar from "./adminNavbar";
import AdminFooter from "./adminFooter";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3c3c3c",
    },
    secondary: {
      main: "#fafafa",
    },
  },
});
const StyledTypography = styled(Typography)(({ theme }) => ({
  marginTop: "50px",
  marginBottom: "20px",
  fontSize: "30px",
  position: "relative",
  display: "inline-block",
  padding: "5px 10px",
  borderBottom: `2.5px solid ${theme.palette.primary.main}`,
  borderLeft: `2.5px solid ${theme.palette.primary.main}`,
  width: "fit-content",
  marginRight: "10px",
  "&::before": {
    content: "''",
    position: "absolute",
    width: "50%",
    height: "2.5px",
    backgroundColor: theme.palette.primary.main,
    top: "-2px",
    left: "-2px",
  },
}));

const EditCtaegorie = ({ user }) => {
  const [name, setName] = useState("");
  const [imageCover, setImageCover] = useState("");
  const [loading, setLoading] = useState(false); // État pour le chargement de la soumission

  const { id } = useParams();

  useEffect(() => {
    // Fetch product details based on the ID from the URL
    const fetchCategorieDetails = async () => {
      try {
        const response = await axios.get(
          `https://ste-icem-server.onrender.com/api/v1/categorie/${id}`
        );
        const categorie = response.data.data.categorie;

        setName(categorie.name);
        setImageCover(categorie.imageCover);
      } catch (error) {}
    };

    fetchCategorieDetails();
  }, [id]);

  const handleSubmit = async (e) => {
    const token = localStorage.getItem("jwtToken");
    e.preventDefault();
    setLoading(true); // Définir l'état du chargement sur true au début de la soumission
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("imageCover", imageCover);

      const response = await axios.patch(
        `https://ste-icem-server.onrender.com/api/v1/categorie/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Redirect or show success message
    } catch (error) {
      alert("Une erreur s'est produite!", error);
    } finally {
      setLoading(false);
      window.location.replace("/admin/ViewCategorieAdmin");
    }
  };

  const handleImageCoverChange = (e) => {
    setImageCover(e.target.files[0]);
  };

  return (
    <ThemeProvider theme={theme}>
      <AdminAppBar user={user} />
      <center>
        <StyledTypography>MODIFIER CATEGORIE</StyledTypography>
      </center>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          paddingLeft: "20%",
          paddingRight: "20%",
          paddingBottom: "8%",
        }}
      >
        <form
          onSubmit={handleSubmit}
          encType="multipart/form-data"
          sx={{ maxWidth: "800px" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Name"
                variant="outlined"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                Image de couverture (max: 1)
              </Typography>
              <TextField
                type="file"
                onChange={handleImageCoverChange}
                accept="image/*"
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "40px",
                }}
              >
                <Button variant="contained" type="submit" disabled={loading}>
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Modifier Categorie"
                  )}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
      <AdminFooter />
    </ThemeProvider>
  );
};

export default EditCtaegorie;
