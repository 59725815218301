import React, { useState } from "react";
import axios from "axios";
import {
  ThemeProvider,
  Typography,
  createTheme,
  styled,
  Box,
  TextField,
  Button,
  Grid,
} from "@mui/material";
import { CircularProgress } from "@mui/material";

import AdminAppBar from "./adminNavbar";
import AdminFooter from "./adminFooter";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3c3c3c",
    },
    secondary: {
      main: "#fafafa",
    },
  },
});
const StyledTypography = styled(Typography)(({ theme }) => ({
  marginTop: "50px",
  marginBottom: "20px",
  fontSize: "30px",
  position: "relative",
  display: "inline-block",
  padding: "5px 10px",
  borderBottom: `2.5px solid ${theme.palette.primary.main}`,
  borderLeft: `2.5px solid ${theme.palette.primary.main}`,
  width: "fit-content",
  marginRight: "10px",
  "&::before": {
    content: "''",
    position: "absolute",
    width: "50%",
    height: "2.5px",
    backgroundColor: theme.palette.primary.main,
    top: "-2px",
    left: "-2px",
  },
}));

const CreateCategory = ({ user }) => {
  const [name, setName] = useState("");
  const [imageCover, setImageCover] = useState(null);
  const [loading, setLoading] = useState(false); // État pour le chargement de la soumission

  const handleSubmit = async (e) => {
    const token = localStorage.getItem("jwtToken");

    e.preventDefault();
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("imageCover", imageCover);

      const response = await axios.post(
        "https://ste-icem-server.onrender.com/api/v1/categorie",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setName("");
      setImageCover(null);
    } catch (error) {
      alert("Une erreur s'est produite!", error.message);
    } finally {
      setLoading(false);
      window.location.replace("/admin/viewCategoriesAdmin");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <AdminAppBar user={user} />
      <center>
        <StyledTypography>NOUVELLE CATEGORIE</StyledTypography>
      </center>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          paddingLeft: "20%",
          paddingRight: "20%",
          paddingBottom: "8%",
        }}
      >
        <form
          onSubmit={handleSubmit}
          encType="multipart/form-data"
          sx={{ maxWidth: "800px" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Nom Categorie"
                variant="outlined"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                Image de couverture (max: 1)
              </Typography>
              <TextField
                type="file"
                onChange={(e) => setImageCover(e.target.files[0])}
                accept="image/*"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "40px",
                }}
              >
                <Button variant="contained" type="submit" disabled={loading}>
                  {loading ? (
                    <CircularProgress size={24} /> // Afficher un indicateur de chargement
                  ) : (
                    "Creer Categorie"
                  )}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
      <AdminFooter />
    </ThemeProvider>
  );
};

export default CreateCategory;
