import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import PlaceIcon from "@mui/icons-material/Place";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import { useNavigate } from "react-router-dom";

export default function Footer({ user }) {
  const navigate = useNavigate();
  const handleNavigateToCategories = () => {
    navigate("/");

    // Delay scrolling until after navigation
    setTimeout(() => {
      const categoriesAnchor = document.getElementById("categories-anchor");
      if (categoriesAnchor) {
        categoriesAnchor.scrollIntoView({ behavior: "smooth" });
      }
    }, 50); // Adjust the delay time as needed
  };
  return (
    <Box sx={{ flexGrow: 1, marginTop: "50px" }}>
      <AppBar position="static" sx={{ backgroundColor: "#ececec" }}>
        <Toolbar
          sx={{
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "30px",
          }}
        >
          <Typography
            variant="h7"
            sx={{
              display: {
                xs: "block",
                md: "none",
                marginTop: "20px",
                color: "black",
              },
            }}
          >
            INSCRIVEZ-VOUS AUX ICEM
          </Typography>
          {!user ? (
            <center>
              <Typography
                variant="h4"
                sx={{
                  display: {
                    xs: "none",
                    md: "block",
                    marginTop: "30px",

                    color: "black",
                  },
                }}
              >
                INSCRIVEZ-VOUS AUX ICEM
              </Typography>
              <Button
                color="inherit"
                onClick={() => {
                  navigate("/signup");
                }}
                sx={{
                  bgcolor: "#063970",
                  "&:hover": {
                    bgcolor: "#042c4e",
                  },
                  marginTop: "20px",
                }}
              >
                INSCRIPTION
              </Button>
            </center>
          ) : null}
        </Toolbar>
        <Box
          sx={{
            flexGrow: 1,
            paddingLeft: "15%",
            paddingRight: "10%",
            marginBottom: "50px",
          }}
        >
          <Grid
            container
            spacing={{ xs: 2, md: 10 }}
            columns={{ xs: 2, sm: 8, md: 24 }}
            color={"black"}
          >
            <Grid item xs={2} sm={4} md={6}>
              <Typography variant="h6" gutterBottom>
                Coordonnées de contact
              </Typography>
              <Typography variant="body1">
                <PlaceIcon /> Route tebolbi km 6, Avenu farhat hached N38, sfax{" "}
                <br />
                <EmailIcon /> steicemicem@gmail.com <br />
                <CallIcon /> +216 99 660 810 <br />
                {/* <FaxIcon /> +216 00 000 000 */}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={4} md={6}>
              <Typography variant="h6" gutterBottom>
                A propos
              </Typography>
              <Typography variant="body1">
                <React.Fragment>
                  <Button
                    color="inherit"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    ACCUEIL
                  </Button>
                </React.Fragment>
                <br />
                <React.Fragment>
                  <Button color="inherit" onClick={handleNavigateToCategories}>
                    NOS CATEGORIES
                  </Button>
                </React.Fragment>
                <br />
                <React.Fragment>
                  <Button
                    color="inherit"
                    onClick={() => {
                      navigate("/nous");
                    }}
                  >
                    QUI SOMMES NOUS?
                  </Button>
                </React.Fragment>
              </Typography>
            </Grid>
            <Grid item xs={2} sm={4} md={6}>
              <Typography variant="h6" gutterBottom>
                Mon compte
              </Typography>
              <Typography variant="body1">
                <Button
                  color="inherit"
                  onClick={() => {
                    navigate("/signup");
                  }}
                >
                  INSCRIPTION
                </Button>
                <br />
                <Button
                  color="inherit"
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  SE CONNECTER
                </Button>
              </Typography>
            </Grid>
            {/* <Grid item xs={2} sm={4} md={6}>
              <Typography variant="h6" gutterBottom>
                Navigation
              </Typography>
              <Typography variant="body1">
                <Button color="inherit">Nos produits</Button>
                <br />
                <Button color="inherit">Nos catalogues</Button>
              </Typography>
            </Grid> */}
          </Grid>
        </Box>
        <Toolbar
          sx={{
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "20px",
            color: "black", // Définir la couleur du texte en noir
          }}
        >
          Conception et réalisation : marketMinds Innovations
        </Toolbar>
      </AppBar>
    </Box>
  );
}
