import React, { useState } from "react";
import {
  Box,
  ThemeProvider,
  Typography,
  createTheme,
  TextField,
} from "@mui/material";
import styled from "@emotion/styled";
import OrdersList from "./listOrders";
import { useNavigate } from "react-router-dom";
import AdminAppBar from "./adminNavbar";
import AdminFooter from "./adminFooter";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3c3c3c",
    },
    secondary: {
      main: "#fafafa",
    },
  },
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginTop: "50px",
  marginBottom: "20px",
  fontSize: "30px",
  position: "relative",
  display: "inline-block",
  padding: "5px 10px",
  borderBottom: `2.5px solid ${theme.palette.primary.main}`,
  borderLeft: `2.5px solid ${theme.palette.primary.main}`,
  width: "fit-content",
  marginRight: "10px",
  "&::before": {
    content: "''",
    position: "absolute",
    width: "50%",
    height: "2.5px",
    backgroundColor: theme.palette.primary.main,
    top: "-2px",
    left: "-2px",
  },
}));

function ViewOrders({ user }) {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState(""); // État pour la valeur de recherche

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value); // Mettre à jour la valeur de recherche lorsqu'elle change
  };

  return (
    <ThemeProvider theme={theme}>
      <AdminAppBar user={user} />
      <center>
        <StyledTypography>Commandes</StyledTypography>
      </center>
      <Box style={{ padding: "2%", float: "right", marginBottom: "20px" }}>
        <TextField
          label="Rechercher"
          variant="outlined"
          size="small"
          value={searchValue}
          onChange={handleSearchChange}
        />
      </Box>
      <OrdersList searchValue={searchValue} />
      <AdminFooter />
    </ThemeProvider>
  );
}

export default ViewOrders;
