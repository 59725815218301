import * as React from "react";

import { Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import Login from "./pages/login";
import Panier from "./pages/panier";
import CreateCategorie from "./pages/admin/createCategorie";
import CategoryDetails from "./pages/categoryDetails";
import CreateProduct from "./pages/admin/createProduct";
import ViewProduct from "./pages/viewProduct";
import ViewProductAdmin from "./pages/admin/viewProductAdmin";
import EditProduct from "./pages/admin/editProduct";
import AdminHome from "./pages/admin/adminHome";
import ViewClients from "./pages/admin/viewClients";
import ViewCategoriesAdmin from "./pages/admin/viewCategoriesAdmin";
import ViewOrders from "./pages/admin/viewOrders";
import Signup from "./pages/signup";
import { ProtectedRoute } from "./pages/protectedRoute";
import { useState } from "react";
import { useEffect } from "react";
import { NotFound } from "./pages/notFound";
import { ProtectedRouteUser } from "./pages/protectedRouteUser";
import { Activation } from "./pages/activation";
import Nous from "./pages/nous";
import ViewSingleProductAdmin from "./pages/admin/viewSingleProductAdmin";
import EditCtaegorie from "./pages/admin/editCategorie";

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Example: Fetch user information from your backend or global state
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("jwtToken");
        // Fetch user data from your authentication system
        const response = await fetch(
          "https://ste-icem-server.onrender.com/api/v1/users/me",
          {
            method: "GET",
            credentials: "include",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.ok) {
          const userData = await response.json();
          setUser(userData);
        } else {
          throw new Error("Failed to fetch user data");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, []);

  return (
    <Routes>
      <Route path="/signup" element={<Signup />} />
      <Route path="/login" element={<Login />} />
      <Route path="/confirm/:activationcode" element={<Activation />} />
      <Route
        path="/admin/viewProductAdmin"
        element={
          <ProtectedRoute user={user}>
            <ViewProductAdmin user={user} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/viewProductAdmin/:id"
        element={
          <ProtectedRoute user={user}>
            <ViewSingleProductAdmin user={user} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/editProduct/:id"
        element={
          <ProtectedRoute user={user}>
            <EditProduct user={user} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/editCategorie/:id"
        element={
          <ProtectedRoute user={user}>
            <EditCtaegorie user={user} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/createCategorie"
        element={
          <ProtectedRoute user={user}>
            <CreateCategorie user={user} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/createProduct"
        element={
          <ProtectedRoute user={user}>
            <CreateProduct user={user} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin"
        element={
          <ProtectedRoute user={user}>
            <AdminHome user={user} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/clients"
        element={
          <ProtectedRoute user={user}>
            <ViewClients user={user} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/viewCategoriesAdmin"
        element={
          <ProtectedRoute user={user}>
            <ViewCategoriesAdmin user={user} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/commandes"
        element={
          <ProtectedRoute user={user}>
            <ViewOrders user={user} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/"
        element={
          <ProtectedRouteUser user={user}>
            <Home user={user} />
          </ProtectedRouteUser>
        }
      />

      <Route
        path="/panier"
        element={
          <ProtectedRouteUser user={user}>
            <Panier user={user} />
          </ProtectedRouteUser>
        }
      />
      <Route
        path="/nous"
        element={
          <ProtectedRouteUser user={user}>
            <Nous user={user} />
          </ProtectedRouteUser>
        }
      />
      <Route
        path="/categories/:id"
        element={
          <ProtectedRouteUser user={user}>
            <CategoryDetails user={user} />
          </ProtectedRouteUser>
        }
      />
      <Route
        path="/viewProduct/:id"
        element={
          <ProtectedRouteUser user={user}>
            <ViewProduct user={user} />
          </ProtectedRouteUser>
        }
      />
      <Route
        path="/*"
        element={
          <ProtectedRouteUser user={user}>
            <NotFound user={user} />
          </ProtectedRouteUser>
        }
      />
    </Routes>
  );
}

export default App;
