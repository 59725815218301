import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box, CardMedia, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import { useNavigate } from "react-router-dom";

function ProductsList({ searchValue }) {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    // Fetch product data from the backend API
    fetch("https://ste-icem-server.onrender.com/api/v1/product")
      .then((response) => response.json())
      .then((data) => {
        setProducts(data.data.data); // Set the products state with the fetched data
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    // Filtrer les produits en fonction de la valeur de recherche
    const filtered = products.filter(
      (product) =>
        (product.code &&
          product.code.toLowerCase().includes(searchValue.toLowerCase())) ||
        (product.designation &&
          product.designation.toLowerCase().includes(searchValue.toLowerCase()))
    );

    setFilteredProducts(filtered);
  }, [searchValue, products]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDeleteProduct = (productId) => {
    const token = localStorage.getItem("jwtToken");
    // Show confirmation dialog before deletion
    const isConfirmed = window.confirm(
      "Êtes-vous sûr de vouloir supprimer ce produit ?"
    );
    if (!isConfirmed) {
      return; // If not confirmed, do nothing
    }

    // Perform deletion logic here
    fetch(`https://ste-icem-server.onrender.com/api/v1/product/${productId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        // Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Assuming you are using JWT token for authentication
      },
    })
      .then((response) => {
        if (response.ok) {
          // Remove the deleted product from the state
          setProducts((prevProducts) =>
            prevProducts.filter((product) => product._id !== productId)
          );
        } else {
          throw new Error("Failed to delete product");
        }
      })
      .catch((error) => {});
  };

  return (
    <Box
      sx={{
        paddingLeft: "2%",
        paddingRight: "2%",
      }}
    >
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
        }}
      >
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                <TableCell>image</TableCell>
                <TableCell align="left">Code</TableCell>
                <TableCell align="left">Designation</TableCell>
                <TableCell align="left">Prix</TableCell>
                <TableCell align="left">Categorie</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredProducts
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={i}
                      onClick={() =>
                        navigate(`/admin/viewProductAdmin/${row._id}`)
                      }
                    >
                      <TableCell align="right">
                        <CardMedia
                          component="img"
                          height="140"
                          image={`https://ste-icem-server.onrender.com/${row.imageCover}`}
                          alt={row.name}
                          style={{
                            width: "50px",
                            height: "50px",
                            objectFit: "cover",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                          }}
                        />
                      </TableCell>
                      <TableCell align="left">{row.code}</TableCell>
                      <TableCell align="left">{row.designation}</TableCell>
                      <TableCell align="left">{row.price} TND</TableCell>
                      <TableCell align="left">{row.categorie.name}</TableCell>
                      <TableCell align="right">
                        <Tooltip title="Modifier ce produit">
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation(); // Empêche la propagation de l'événement de clic
                              navigate(`/admin/editProduct/${row._id}`);
                            }}
                          >
                            <BuildOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Supprimer ce produit">
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation(); // Empêche la propagation de l'événement de clic
                              handleDeleteProduct(row._id);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ backgroundColor: "#f5f5f5" }}
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filteredProducts.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

export default ProductsList;
