import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  ThemeProvider,
  Typography,
  createTheme,
  styled,
  Box,
  TextField,
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import AdminAppBar from "./adminNavbar";
import AdminFooter from "./adminFooter";
import { CircularProgress } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3c3c3c",
    },
    secondary: {
      main: "#fafafa",
    },
  },
});
const StyledTypography = styled(Typography)(({ theme }) => ({
  marginTop: "50px",
  marginBottom: "20px",
  fontSize: "30px",
  position: "relative",
  display: "inline-block",
  padding: "5px 10px",
  borderBottom: `2.5px solid ${theme.palette.primary.main}`,
  borderLeft: `2.5px solid ${theme.palette.primary.main}`,
  width: "fit-content",
  marginRight: "10px",
  "&::before": {
    content: "''",
    position: "absolute",
    width: "50%",
    height: "2.5px",
    backgroundColor: theme.palette.primary.main,
    top: "-2px",
    left: "-2px",
  },
}));

const CreateProduct = ({ user }) => {
  const [code, setCode] = useState("");
  const [designation, setDesignation] = useState("");
  const [price, setPrice] = useState(0);
  const [description, setDescription] = useState("");
  const [imageCover, setImageCover] = useState(null);
  const [images, setImages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [popularProduct, setPopularProduct] = useState(false);
  const [prixSansPromo, setPrixSansPromo] = useState(false);
  const [loading, setLoading] = useState(false); // État pour le chargement de la soumission

  useEffect(() => {
    fetch("https://ste-icem-server.onrender.com/api/v1/categorie")
      .then((response) => response.json())
      .then((data) => {
        setCategories(data.data.data);
      })
      .catch((error) => {});
  }, []);

  const handleSubmit = async (e) => {
    const token = localStorage.getItem("jwtToken");
    e.preventDefault();
    setLoading(true); // Définir l'état du chargement sur true au début de la soumission

    try {
      const formData = new FormData();
      formData.append("code", code);
      formData.append("designation", designation);
      formData.append("price", price);
      formData.append("description", description);
      formData.append("imageCover", imageCover);
      images.forEach((image) => formData.append("images", image));
      formData.append("categorie", selectedCategory);
      formData.append("popularProduct", popularProduct);
      formData.append("prixSansPromo", prixSansPromo);

      const response = await axios.post(
        "https://ste-icem-server.onrender.com/api/v1/product",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setCode("");
      setDesignation("");
      setPrice(0);
      setDescription("");
      setImageCover(null);
      setImages([]);
      setSelectedCategory("");
      setPopularProduct(false);
      setPrixSansPromo(0);
    } catch (error) {
      alert("Une erreur s'est produite!", error);
    } finally {
      setLoading(false);
      window.location.replace("/admin/ViewProductAdmin");
    }
  };

  const handleImageCoverChange = (e) => {
    setImageCover(e.target.files[0]);
  };

  const handleImagesChange = (e) => {
    setImages([...images, ...e.target.files]);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handlePopularProductChange = (e) => {
    setPopularProduct(e.target.checked);
  };

  return (
    <ThemeProvider theme={theme}>
      <AdminAppBar user={user} />
      <center>
        <StyledTypography>NOUVEAU PRODUIT</StyledTypography>
      </center>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          paddingLeft: "20%",
          paddingRight: "20%",
          paddingBottom: "8%",
        }}
      >
        <form
          onSubmit={handleSubmit}
          encType="multipart/form-data"
          sx={{ maxWidth: "800px" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Code"
                variant="outlined"
                fullWidth
                value={code}
                onChange={(e) => setCode(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Designation"
                variant="outlined"
                fullWidth
                value={designation}
                onChange={(e) => setDesignation(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Prix"
                variant="outlined"
                type="number"
                fullWidth
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Prix Sans Promo"
                variant="outlined"
                type="number"
                fullWidth
                value={prixSansPromo}
                onChange={(e) => setPrixSansPromo(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Description"
                variant="outlined"
                fullWidth
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                Image de couverture (max: 1)
              </Typography>
              <TextField
                type="file"
                onChange={handleImageCoverChange}
                accept="image/*"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                Autres images (max: 5)
              </Typography>
              <TextField
                type="file"
                onChange={handleImagesChange}
                accept="image/*"
                fullWidth
                inputProps={{
                  multiple: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Categorie</InputLabel>
                <Select
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  fullWidth
                  required
                  label="Categorie"
                >
                  {categories.map((category) => (
                    <MenuItem key={category._id} value={category._id}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Box
                sx={{
                  border: "1px solid #c4c4c4",
                  borderRadius: "4px",
                  padding: "6px",
                }}
              >
                <FormControlLabel
                  sx={{
                    display: "flex",
                    paddingLeft: "1%",
                  }}
                  control={
                    <Checkbox
                      checked={popularProduct}
                      onChange={handlePopularProductChange}
                    />
                  }
                  label="Produit populaire"
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "40px",
                }}
              >
                <Button variant="contained" type="submit" disabled={loading}>
                  {loading ? (
                    <CircularProgress size={24} /> // Afficher un indicateur de chargement
                  ) : (
                    "Creer Produit"
                  )}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
      <AdminFooter />
    </ThemeProvider>
  );
};

export default CreateProduct;
