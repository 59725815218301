import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

export default function Footer() {
  return (
    <Box sx={{ flexGrow: 1, marginTop: "220px" }}>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#ececec",
          top: "auto",
          bottom: 0,
          marginTop: "auto", // Add marginTop: "auto" to push the footer to the bottom
        }}
      >
        <Toolbar
          sx={{
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "20px",
            color: "black", // Définir la couleur du texte en noir
          }}
        >
          Conception et réalisation : marketMinds Innovations
        </Toolbar>
      </AppBar>
    </Box>
  );
}
