import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { grey } from "@mui/material/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, CardActionArea, Grid } from "@mui/material";
import { Link } from "react-router-dom";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function BestProductCard(user) {
  const [expanded, setExpanded] = React.useState({});
  const [bestProducts, setBestProducts] = React.useState([]);

  React.useEffect(() => {
    // Fetch best products from the server
    const fetchBestProducts = async () => {
      try {
        const response = await fetch(
          "https://ste-icem-server.onrender.com/api/v1/product/bestProducts"
        );
        if (response.ok) {
          const data = await response.json();
          setBestProducts(data);
        } else {
          throw new Error("Failed to fetch best products");
        }
      } catch (error) {}
    };

    fetchBestProducts();
  }, []);

  const StyledTypography = styled(Typography)(({ theme }) => ({
    marginTop: "70px",
    marginBottom: "70px",
    fontSize: "30px",
    position: "relative",
    display: "inline-block",
    padding: "5px 10px",
    borderBottom: `2.5px solid ${theme.palette.primary.main}`,
    borderLeft: `2.5px solid ${theme.palette.primary.main}`,
    width: "fit-content",
    marginRight: "10px",
    "&::before": {
      content: "''",
      position: "absolute",
      width: "50%",
      height: "2.5px",
      backgroundColor: theme.palette.primary.main,
      top: "-2px",
      left: "-2px",
    },
  }));

  const handleAddToCart = (id) => {
    const token = localStorage.getItem("jwtToken");
    if (!user || !user.user) {
      // Vérifiez si user ou user.user est nul
      alert("Veuillez vous connecter pour ajouter des articles au panier.");
      return;
    }
    fetch("https://ste-icem-server.onrender.com/api/v1/cart/addtocart", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        cartItems: [{ product: id, quantity: 1 }],
      }),
    })
      .then((response) => response.json())
      .then((data) => {})
      .catch((error) => {});
  };

  const handleExpandClick = (id) => {
    setExpanded((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return bestProducts.length > 0 ? (
    <Box
      sx={{
        flexGrow: 1,
        paddingLeft: "10%",
        paddingRight: "10%",

        justifyContent: "center", // Centrer les éléments horizontalement
      }}
    >
      <center>
        <StyledTypography>PRODUITS POPULAIRES</StyledTypography>
      </center>
      <Grid
        container
        spacing={4} // Ajoutez de l'espace entre les éléments de la grille
      >
        {bestProducts.map((product) => (
          <Grid item xs={12} sm={6} md={4} key={product.id}>
            <Card sx={{ maxWidth: 300, backgroundColor: "#f0f0f0" }}>
              <CardHeader
                title={
                  product.designation.length > 20
                    ? product.designation.substring(0, 20) + "..."
                    : product.designation
                }
                subheader={product.categorie.name}
                avatar={
                  <Avatar sx={{ bgcolor: grey[500] }} aria-label="recipe">
                    {product.designation.charAt(0).toUpperCase()}
                  </Avatar>
                }
                action={
                  // <Typography>{product.price} TND</Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    style={{ fontSize: "18px" }}
                  >
                    {product.prixSansPromo ? (
                      <>
                        <span style={{ textDecoration: "line-through" }}>
                          {product.prixSansPromo} TND
                        </span>
                        <br />
                        <b style={{ color: "#C70039" }}>{product.price} TND</b>
                      </>
                    ) : (
                      <>
                        <b style={{ color: "#C70039" }}>{product.price} TND</b>
                      </>
                    )}
                  </Typography>
                }
              />

              <CardActionArea sx={{ height: "50%" }}>
                <Link to={`/viewProduct/${product.id}`}>
                  <CardMedia
                    component="img"
                    image={`https://ste-icem-server.onrender.com/${product.imageCover}`}
                    alt="Fallback Image"
                    sx={{
                      objectFit: "contain",
                      height: "250px",
                      width: "100%",
                      padding: "5%",
                    }}
                  />
                </Link>
              </CardActionArea>

              <CardActions disableSpacing>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => handleAddToCart(product.id)}
                >
                  Ajouter au panier
                </Button>
                <ExpandMore
                  expand={expanded[product.id]} // Utiliser l'état élargi pour la carte spécifique
                  onClick={() => handleExpandClick(product.id)} // Passer l'identifiant du produit à handleExpandClick
                  aria-expanded={expanded[product.id]}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </CardActions>
              <Collapse in={expanded[product.id]} timeout="auto" unmountOnExit>
                <CardContent>
                  <Typography paragraph sx={{ fontSize: "12px" }}>
                    {product.description}
                  </Typography>
                </CardContent>
              </Collapse>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  ) : null;
}
export default BestProductCard;
