import React from "react";
import { Box, ThemeProvider, Typography, createTheme } from "@mui/material";
import ResponsiveAppBar from "../components/navbar";
import styled from "@emotion/styled";
import Footer from "../components/footer";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3c3c3c",
    },
    secondary: {
      main: "#fafafa",
    },
  },
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginTop: "50px",
  marginBottom: "50px",
  fontSize: "30px",
  position: "relative",
  display: "inline-block",
  padding: "5px 10px",
  borderBottom: `2.5px solid ${theme.palette.primary.main}`,
  borderLeft: `2.5px solid ${theme.palette.primary.main}`,
  width: "fit-content",
  marginRight: "10px",
  "&::before": {
    content: "''",
    position: "absolute",
    width: "50%",
    height: "2.5px",
    backgroundColor: theme.palette.primary.main,
    top: "-2px",
    left: "-2px",
  },
}));

function Nous({ user }) {
  return (
    <ThemeProvider theme={theme}>
      <ResponsiveAppBar user={user} />
      <Box sx={{ marginLeft: "10%", marginRight: "10%", marginBottom: "10%" }}>
        <center>
          <StyledTypography variant="h4">QUI SOMMES NOUS?</StyledTypography>
        </center>
        <img
          src={"/DSC06415.jpg"}
          alt="STE ICEM"
          style={{ width: "100%", display: "block", marginBottom: "20px" }}
        />
        <Typography variant="body1" paragraph>
          ICEM, leader du secteur de la commercialisation d'articles pour outils
          à main, appareils électroménagers, équipements et accessoires
          connexes, se distingue par son engagement envers l'excellence et
          l'innovation. Fort d'une expérience éprouvée dans l'industrie, ICEM
          s'efforce de fournir à ses clients des produits de haute qualité,
          répondant aux normes les plus strictes de fiabilité et de performance.
        </Typography>
        <Typography variant="body1" paragraph>
          En tant que pilier de l'industrie, ICEM se consacre à offrir une gamme
          diversifiée d'articles, couvrant ainsi les besoins variés des
          professionnels et des particuliers.
        </Typography>
        <Typography variant="body1" paragraph>
          ICEM s'engage à fournir un service exceptionnel, une satisfaction
          client inégalée et à rester à la pointe des tendances du marché.
        </Typography>
      </Box>
      <Footer />
    </ThemeProvider>
  );
}

export default Nous;
