import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box, Checkbox, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ListIcon from "@mui/icons-material/List";
import OrdersModal from "./ordersModal";
import axios from "axios";

function OrdersList({ searchValue }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});

  // Fonction pour trier les commandes par date de création (du plus récent au plus ancien)
  const sortOrdersByDate = (orders) => {
    return orders.slice().sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
  };

  const handleOpenModal = (items) => {
    setIsModalOpen(true);
    setItems(items);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    // Fetch product data from the backend API
    fetch("https://ste-icem-server.onrender.com/api/v1/order/getOrders", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        // Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Assuming you are using JWT token for authentication
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Set the products state with the fetched data
        setOrders(data.orders);
        // Initialize checkedItems with the initial confirmation status of each order
        const initialCheckedItems = {};
        data.orders.forEach((order) => {
          initialCheckedItems[order._id] = order.confirme;
        });
        setCheckedItems(initialCheckedItems);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    // Filtrer et trier les commandes en fonction de la valeur de recherche
    if (orders) {
      let filtered = orders.filter(
        (order) =>
          (order.user &&
            order.user.name
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (order.createdAt &&
            order.createdAt.toLowerCase().includes(searchValue.toLowerCase()))
      );

      // Trier les commandes filtrées
      filtered = sortOrdersByDate(filtered);

      setFilteredOrders(filtered);
    }
  }, [searchValue, orders]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCheckboxChange = (orderId) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [orderId]: !prevCheckedItems[orderId],
    }));
  };

  const updateOrderConfirm = async (orderId, newConfirmValue) => {
    const token = localStorage.getItem("jwtToken");
    try {
      // Envoyer une requête PATCH à la route du serveur pour mettre à jour l'ordre
      await axios.patch(
        `https://ste-icem-server.onrender.com/api/v1/order/updateOrder/${orderId}`,
        {
          confirme: newConfirmValue,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            // Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Assuming you are using JWT token for authentication
          },
        }
      );
    } catch (error) {}
  };

  useEffect(() => {
    // Mettre à jour les commandes confirmées sur la sortie de la page
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleBeforeUnload = () => {
    Object.keys(checkedItems).forEach((orderId) => {
      if (
        checkedItems[orderId] !==
        orders.find((order) => order._id === orderId).confirme
      ) {
        updateOrderConfirm(orderId, checkedItems[orderId]);
      }
    });
  };

  return (
    <Box
      sx={{
        paddingLeft: "2%",
        paddingRight: "2%",
      }}
    >
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
        }}
      >
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                <TableCell>Client</TableCell>
                <TableCell align="left">date</TableCell>
                <TableCell align="left">Montant Total</TableCell>
                <TableCell align="left">Confirmé</TableCell>
                <TableCell align="right">liste des produits</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredOrders
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                      <TableCell align="left">
                        {row.user && row.user.name}
                      </TableCell>
                      <TableCell align="left">
                        {new Date(row.createdAt).toLocaleDateString("fr-FR")}
                      </TableCell>
                      <TableCell align="left">{row.totalAmount}</TableCell>
                      <TableCell align="left">
                        <Checkbox
                          checked={checkedItems[row._id] || false}
                          onChange={() => {
                            handleCheckboxChange(row._id);
                            updateOrderConfirm(row._id, !checkedItems[row._id]);
                          }}
                          key={row._id}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip title="liste des produits">
                          <IconButton
                            onClick={() => handleOpenModal(row.items)}
                          >
                            <ListIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ backgroundColor: "#f5f5f5" }}
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filteredOrders.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <OrdersModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        items={items}
      />
    </Box>
  );
}

export default OrdersList;
