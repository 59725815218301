import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box, Button, CardActionArea, CardActions } from "@mui/material";
import { Link } from "react-router-dom";
import CardHeader from "@mui/material/CardHeader";

export default function ProductCard({ product, user }) {
  const handleAddToCart = () => {
    const token = localStorage.getItem("jwtToken");
    if (!user) {
      alert("Veuillez vous connecter pour ajouter des articles au panier.");
      return;
    }
    fetch("https://ste-icem-server.onrender.com/api/v1/cart/addtocart", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        cartItems: [{ product: product.id, quantity: 1 }],
      }),
    })
      .then((response) => response.json())
      .then((data) => {})
      .catch((error) => {});
  };

  React.useEffect(() => {
    console.log("user", user);
  }, [user]);

  return (
    <Card
      sx={{
        maxWidth: 350,
        backgroundColor: "#f0f0f0",
      }}
    >
      <CardActionArea>
        <Link to={`/viewProduct/${product.id}`}>
          <CardMedia
            component="img"
            height="auto"
            width="100%"
            image={`https://ste-icem-server.onrender.com/${product.imageCover}`}
            alt="Product Image"
            sx={{
              objectFit: "contain",
              height: "250px",
              width: "100%",
              padding: "5%",
            }}
          />
        </Link>
        <CardContent sx={{ flexGrow: 1 }}>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ fontSize: "20px" }}
          >
            {product.designation.length > 20
              ? product.designation.substring(0, 40) + "..."
              : product.designation}
          </Typography>
          {product.categorie ? (
            <>
              <Typography
                variant="body2"
                color="text.secondary"
                style={{ fontSize: "18px" }}
              >
                {product.prixSansPromo ? (
                  <>
                    <span style={{ textDecoration: "line-through" }}>
                      {product.prixSansPromo} TND
                    </span>
                    <br />
                    <b style={{ color: "#C70039" }}>{product.price} TND</b>
                  </>
                ) : (
                  <>
                    <b style={{ color: "#C70039" }}>{product.price} TND</b>
                  </>
                )}
              </Typography>

              <Typography variant="body2" color="text.secondary">
                Catégorie: {product.categorie.name}
              </Typography>
            </>
          ) : (
            <Typography variant="body2" color="text.secondary">
              Loading...
            </Typography>
          )}
        </CardContent>
      </CardActionArea>

      <CardActions>
        <Box width="100%" display="flex" justifyContent="center">
          <Button size="small" color="primary" onClick={handleAddToCart}>
            ajouter au panier
          </Button>
        </Box>
      </CardActions>
    </Card>
  );
}
