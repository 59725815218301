import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ListIcon from "@mui/icons-material/List";
import BasicModal from "./listCommandesModal";

function ClientsList({ searchValue }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState();

  const handleOpenModal = (id) => {
    setIsModalOpen(true);
    setSelectedClientId(id);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    // Fetch product data from the backend API
    const token = localStorage.getItem("jwtToken");

    fetch("https://ste-icem-server.onrender.com/api/v1/users", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        // Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Assuming you are using JWT token for authentication
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Set the products state with the fetched data
        setClients(data.data.data); // Modifier ici pour extraire les clients de data.data
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    // Filtrer les produits en fonction de la valeur de recherche
    const filtered = clients.filter(
      (client) =>
        (client.name &&
          client.name.toLowerCase().includes(searchValue.toLowerCase())) ||
        (client.email &&
          client.email.toLowerCase().includes(searchValue.toLowerCase()))
    );

    setFilteredClients(filtered);
  }, [searchValue, clients]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box
      sx={{
        paddingLeft: "2%",
        paddingRight: "2%",
      }}
    >
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
        }}
      >
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                <TableCell>Nom et Prénom</TableCell>
                <TableCell align="left">Email</TableCell>
                <TableCell align="right">Commandes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredClients
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">{row.email}</TableCell>

                      <TableCell align="right">
                        <Tooltip title="liste des commandes">
                          <IconButton onClick={() => handleOpenModal(row._id)}>
                            <ListIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ backgroundColor: "#f5f5f5" }}
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filteredClients.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <BasicModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        id={selectedClientId}
      />
    </Box>
  );
}

export default ClientsList;
