import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { CardActionArea, Typography, createTheme } from "@mui/material";
import AdminAppBar from "./adminNavbar";
import { ThemeProvider } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import AdminFooter from "./adminFooter";
import { useState } from "react";
import { useEffect } from "react";
import { CircularProgress } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  display: "flex", // Align items vertically
  alignItems: "center", // Center vertically
  justifyContent: "center", // Center horizontally
  [theme.breakpoints.down("sm")]: {
    height: "50px", // Height for small screens
  },
  [theme.breakpoints.up("sm")]: {
    height: "200px", // Height for medium screens and up
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#3c3c3c",
    },
    secondary: {
      main: "#fafafa",
    },
  },
});

const UnderlinedText = styled(Typography)(({ theme }) => ({
  position: "relative",
  color: theme.palette.primary.main,
  "&::before": {
    content: "''",
    position: "absolute",
    bottom: "-4px",
    left: 0,
    width: "50%",
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
}));

export default function AdminHome({ user }) {
  const navigate = useNavigate();
  const [unconfirmedOrders, setUnconfirmedOrders] = useState([]);
  const [loading, setLoading] = useState(false); // État pour le chargement de la soumission

  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    setLoading(true);

    fetch("https://ste-icem-server.onrender.com/api/v1/order/getOrders", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const unconfirmedOrders = data.orders.filter(
          (order) => !order.confirme
        );
        setUnconfirmedOrders(unconfirmedOrders);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching orders:", error);
      });
  }, []);

  const unconfirmedOrdersCount = unconfirmedOrders.length;

  if (loading) {
    // Show a loading indicator while checking the user's role
    return <CircularProgress />;
  }
  return (
    <ThemeProvider theme={theme}>
      <AdminAppBar user={user} />
      <Box sx={{ flexGrow: 1, paddingLeft: "10%", paddingRight: "10%" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CardActionArea onClick={() => navigate("/admin/commandes")}>
              <Item
                sx={{
                  backgroundColor:
                    unconfirmedOrdersCount > 0 ? "#A5B1C7" : "inherit",
                }}
              >
                <UnderlinedText variant="h4">
                  Commandes{" "}
                  {unconfirmedOrdersCount > 0 && `(${unconfirmedOrdersCount})`}
                </UnderlinedText>
              </Item>
            </CardActionArea>
          </Grid>

          <Grid item xs={12} md={6}>
            <CardActionArea onClick={() => navigate("/admin/clients")}>
              <Item>
                <UnderlinedText variant="h4">Clients</UnderlinedText>
              </Item>
            </CardActionArea>
          </Grid>
          <Grid item xs={12} md={6}>
            <CardActionArea
              onClick={() => navigate("/admin/viewCategoriesAdmin")}
            >
              <Item>
                <UnderlinedText variant="h4">Categories</UnderlinedText>
              </Item>
            </CardActionArea>
          </Grid>
          <Grid item xs={12} md={6}>
            <CardActionArea onClick={() => navigate("/admin/ViewProductAdmin")}>
              <Item>
                {" "}
                <UnderlinedText variant="h4">Produits</UnderlinedText>
              </Item>
            </CardActionArea>
          </Grid>
        </Grid>
      </Box>
      <AdminFooter />
    </ThemeProvider>
  );
}
