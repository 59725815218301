import React from "react";

import {
  CCarousel,
  CCarouselCaption,
  CCarouselItem,
  CImage,
} from "@coreui/react";

import "@coreui/coreui/dist/css/coreui.min.css";

const images = [
  {
    label: "Ste ICEM",
    imgPath: "/carrosel/DSC06432.jpg",
  },
  {
    label: "Ste ICEM",
    imgPath: "/carrosel/DSC06429.jpg",
  },
  {
    label: "Ste ICEM",
    imgPath: "/carrosel/DSC06406.jpg",
  },
  {
    label: "Ste ICEM",
    imgPath: "/carrosel/DSC06407.jpg",
  },

  {
    label: "Ste ICEM",
    imgPath: "/carrosel/DSC06420.jpg",
  },
];

function SwipeableTextMobileStepper() {
  return (
    <CCarousel controls indicators>
      {images.map((image, index) => (
        <CCarouselItem key={index}>
          <CImage
            className="d-block w-100"
            src={image.imgPath}
            alt={`slide ${index}`}
            style={{ maxHeight: "560px" }} // Redéfinir la hauteur maximale de l'image
          />
          <CCarouselCaption
            className="d-none d-md-block"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.4)", // Arrière-plan avec opacité
              color: "white", // Texte en blanc
              paddingLeft: "100px",
              paddingRight: "0px",
              paddingTop: "30px",
              paddingBottom: "30px",
              borderRadius: "5px", // Coins arrondis
              backdropFilter: "blur(1px)", // Flou de l'arrière-plan
            }}
          >
            <h4>INTER CONFORT D'EQUIPEMENT MODERNE</h4>
          </CCarouselCaption>
        </CCarouselItem>
      ))}
    </CCarousel>
  );
}

export default SwipeableTextMobileStepper;
