import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  createTheme,
  ThemeProvider,
  Button,
  Typography,
  CardMedia,
} from "@mui/material";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ResponsiveAppBar from "../components/navbar";
import Footer from "../components/footer";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3c3c3c",
    },
    secondary: {
      main: "#fafafa",
    },
  },
});

const ViewProduct = ({ user }) => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [selectedImage, setSelectedImage] = useState("");
  const [images, setImages] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    fetch(`https://ste-icem-server.onrender.com/api/v1/product/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setProduct(data.data.product);
        setSelectedImage(data.data.product.imageCover);
        setImages([data.data.product.imageCover, ...data.data.product.images]);
      });
  }, [id]);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleAddToCart = () => {
    const token = localStorage.getItem("jwtToken");
    if (!user) {
      alert("Veuillez vous connecter pour ajouter des articles au panier.");
      return;
    }
    fetch("https://ste-icem-server.onrender.com/api/v1/cart/addtocart", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        cartItems: [{ product: product.id, quantity: 1 }], // Assuming you want to add one item to the cart
      }),
    })
      .then((response) => response.json())
      .then((data) => {})
      .catch((error) => {});
  };

  return (
    <ThemeProvider theme={theme}>
      <ResponsiveAppBar user={user} />
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 10, md: 20 }}
        sx={{
          padding: {
            xs: "20px", // Adjust the padding for mobile view
            md: "90px", // Original padding for larger screens
          },
        }}
      >
        <Grid item xs={12} md={7}>
          <Card
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#f0f0f0",
              borderRadius: "50px",
            }}
          >
            <CardContent sx={{ flex: "1 0 auto" }}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CardMedia
                  component="img"
                  height="100%"
                  width="auto"
                  image={`https://ste-icem-server.onrender.com/${selectedImage}`}
                  alt={product ? product.designation : ""}
                  sx={{
                    width: { xs: "300px", md: "400px" },
                    height: "400px",
                    objectFit: "contain",
                  }}
                />
              </Box>
            </CardContent>
            <CardContent sx={{ flex: "0 0 auto" }}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Grid container direction="row" spacing={1}>
                  {images.map((image, index) => (
                    <Grid item key={index}>
                      <Button onClick={() => handleImageClick(image)}>
                        <CardMedia
                          component="img"
                          height="140"
                          image={`https://ste-icem-server.onrender.com/${image}`}
                          alt={product ? product.designation : ""}
                          style={{
                            width: "50px",
                            height: "50px",
                            objectFit: "contain",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                          }}
                        />
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={5}>
          <Card
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              borderRadius: "50px",
            }}
          >
            <CardContent
              sx={{
                flex: "1 0 auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: "20px",
                backgroundColor: "#f0f0f0",
                border: "0px solid #ccc",
              }}
            >
              <div style={{ marginBottom: "20px" }}>
                <Typography variant="h5" gutterBottom>
                  {product && product.designation}
                </Typography>

                <Typography variant="h6">
                  Prix:{" "}
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    style={{ fontSize: "18px" }}
                  >
                    {product && product.prixSansPromo ? (
                      <>
                        <span style={{ textDecoration: "line-through" }}>
                          {product.prixSansPromo} TND
                        </span>
                        <br />
                        <b style={{ color: "#C70039" }}>{product.price} TND</b>
                      </>
                    ) : (
                      <>
                        <b style={{ color: "#C70039" }}>
                          {product && product.price} TND
                        </b>
                      </>
                    )}
                  </Typography>
                </Typography>

                <Typography variant="h6">
                  Description:
                  <br />
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {product &&
                    product.description &&
                    product.description.split(".").map((sentence, index) => (
                      <React.Fragment key={index}>
                        {sentence.trim()}
                        <br />
                      </React.Fragment>
                    ))}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={handleAddToCart}
                >
                  Ajouter au panier
                </Button>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Footer user={user} />
    </ThemeProvider>
  );
};

export default ViewProduct;
